.container {
    padding: 80px 80px;
}

.container-fluid-content {
    padding-bottom: 90px;
    padding-top: 60px;
    align-content: center;
    text-align: center;
}

.profile {
    border: 10px solid transparent;
    margin-bottom: 25px;
    width: 80%;
    height: 80%;
}

.row {
    padding: 10px 1px;
}

.pannel-profile-header {
    padding: 15px;
    margin: 5px;
}

.thumbnail {
    padding: 10px 10px 10px 10px;
    border: none;
    border-radius: 5px;
    background-clip: padding-box;
    height: 400px;
    width: 300px;
}

.thumbnail img {
    border-radius: 50%;
}

.jumbotron {
    height: auto;
    background-color: #09d3ac;
    box-shadow: white;
    border-radius: revert;
}

.jumbotron-fluid {
    height: auto;
    background-color: gray;
    color: #ffffff;
    box-shadow: white;
    border-radius: revert;
}

.header-container {
    height: 400px;

}

.header-img {
    background-size: cover;
    background-position: center center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url("https://media.giphy.com/media/PnazV27Tc5RFIsw4WV/giphy.gif");
    min-height: 500px;
}

.header-img-alt {
    background-size: cover;
    background-position: center center;
    background-image: url("../resources/images/backdrop-chalk&feet.jpg");
    min-height: 600px;
}

.header-img-alt-city {
    background-size: cover;
    background-position: center center;
    background-image: url("../resources/images/Orch_OneStep_Webpages_ForChurches-02.png");
    min-height: 600px;
}

.header-img-alt-chalk {
    background-size: cover;
    background-position: center center;
    background-image: url("../resources/images/backdrop-chalk.jpg");
    min-height: 600px;
}

.header-img-alt-walk {
    background-size: cover;
    background-position: center center;
    background-image: url("../resources/images/backdrop-walk.jpg");
    min-height: 600px;
}

.primary-color-background {
    background-color: black;
}

.transparent-color-background {
    background-color: transparent;
}

.secondary-color-background {
    background-color: gray;
}

.text-color-light {
    color: white;
    padding: 5px !important;
    margin: 0px;
}

.text-color-gold {
    color: #aa854b;
}

.text-center {
    text-align: center;
}

.footer {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    color: #ffffff;

}

.title {
    margin: 60px 0px 60px 0px;
}

p {
    font-size: 110%;
    margin: 60px 0px 60px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.red-button {
    margin-left: 10px;
    margin-right: 10px;
    background-color: tomato;
    border-radius: 5px;
}

.nav-button {
    margin-right: 5px;
    margin-left: 5px;
}

.nav-list {
    padding-left: 80px;
    padding-right: 20px;
    padding-top: 50px;
}

.nav-list-footer {
    padding: 30px 0px;
}

.gold-drop-background {
    background-image: url("../resources/images/chalk-gold-background.jpg");
    background-size: cover;
    color: white;
}

.gold-church-background {
    background-image: url("../resources/images/church-gold-background.jpg");
    background-size: cover;
    color: white;
}

.logo-img {
    width: 300px;
    height: 75px;
}

.bookCoverImg {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    min-width: 350px;
    max-width: 450px;
    max-height: 800px;
}

.greyed-out-transparent-button {
    background-color: black;
    -webkit-filter: opacity(40%);
    border-radius: 10px;
    padding: 10px 20px;
}

.button {
    padding: 15px 50px;
    background-color: #222222;
    border-radius: 30px;
    margin: 30px 30px;
}

.button-gold {
    padding: 10px 30px !important;
    background-color: #aa854b;
    border-radius: 30px;
    margin: 10px 20px 20px !important;
}

.button-transparent {
    padding: 10px 30px !important;
    background-color: transparent;
    border-radius: 30px;
    margin: 10px 20px 20px !important;
}

.text-container {
    vertical-align: center;
    padding: 0px 10px;
}

.rounded {
    border-radius: 25px !important;
}

.navbar {
    box-shadow: none !important;
}

.btn {
    box-shadow: none !important;
}

.nav-button {

    border-radius: 40px !important;
    border: solid #aa854b 1px !important;
}

.container-custom {
    align-content: center;
}

@font-face {
    font-family: OrchardMyriadPro;
    src: url(../resources/content/fonts/MyriadPro-Regular.otf);
}

@font-face {
    font-family: OrchardCircularBlock;
    src: url(../resources/content/fonts/CircularStd-Black.otf);
}

@font-face {
    font-family: OrchardCircularStandard;
    src: url(../resources/content/fonts/CircularStd-Book.otf);
}


body {
    font-family: OrchardMyriadPro, serif;

}

body::before {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url("../resources/images/chalk-gold-background.jpg") url("../resources/images/backdrop-chalk&feet.jpg") url("../resources/images/backdrop-chalk.jpg") url("../resources/images/backdrop-walk.jpg") url("../resources/images/church-gold-background.jpg") url("../resources/images/Orch_OneStep_Webpages_ForChurches-02.png");
}

* {
    font-family: OrchardMyriadPro, serif;
}

p {
    font-size: 20px;
    font-family: OrchardCircularStandard, serif;
    margin-top: 10px;
    margin-bottom: 10px;
}

.big-letters {
    font-size: 6vw;
}

.big-fat-letters {
    font-family: OrchardCircularBlock, serif;
    font-size: 60px;
}

.nav-link {
    font-family: OrchardCircularBlock, serif;
    font-weight: bold;
}

h1 {
    font-family: OrchardCircularStandard, serif;
}

.box-img {
    height: 100%;
    width: 100%;
    color: #09d3ac;
}

footer.global {
    background: black;
    color: white;
    text-align: center;
    font-size: .7em;
    padding: 30px;
}

@media (min-width: 49em) {
    footer.global {
        padding: 85px 20px;
        font-size: .85em;
    }
}

@media (min-width: 55em) {
    footer.global {
        padding: 85px 45px;
    }
}

footer.global .columns {
    overflow: hidden;
    margin: 0 auto;
}

footer.global .column {
    padding: 20px 0;
    max-width: 250px;
    margin: 0 auto;
}

@media (min-width: 43em) {
    footer.global .column {
        float: left;
        width: 30%;
        max-width: none;
        box-sizing: border-box;
        padding-bottom: 500px;
        margin-bottom: -500px;
    }
}

footer.global .column:nth-child(1) {
    font-size: .9em;
}

footer.global .column:nth-child(2) {
    border-color: white;
    border-style: solid;
    border-width: 1px 0;
}

@media (min-width: 43em) {
    footer.global .column:nth-child(2) {
        border-width: 0 1px;
        width: 40%;
    }
}

footer.global .column:nth-child(3) p {
    text-transform: uppercase;
    line-height: 2.4;
    font-size: .6em;
    letter-spacing: .1em;
}

footer.global a {
    display: inline-block;
}

footer.global a[href^="tel"] {
    color: #00cc99;
}

footer.global a:hover {
    color: #ff6634;
}

footer.global a.cta {
    border: 1px solid white;
    margin: 0 4px 10px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    text-indent: -50em;
    background-repeat: no-repeat;
    background-position: center center;
}

footer.global a.cta:hover {
    border-color: #ff6634;
}

footer.global a.cta.facebook {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAWCAAAAADRUyLGAAAAgElEQVQY02NgAAMB/UBXYzYwk63uxc///z+rgTl1/8FAE8SW/Qxifn0PlkkFMi+5KiqC9ZQCOVUMUFAI5JQic8rBrIgt664DOdfXbVkpyND6HwbecyNxjjAxFD679xokfO9ZK9Ap3Nwge+q4uSFOi4GbBgTxyJZSlyMbERED9iUAv95vVmUFZ0YAAAAASUVORK5CYII=);
    background-size: 6px auto;
}

footer.global a.cta.email {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAQCAAAAAA3htD6AAAAtUlEQVQY022QIRKDMBBFv8DkBlwAm8kRMHhcBBbBFSo5CgqBYoYjYNAIRAeugMB0ML/ZQDstwxObn6d2P6BuAJKnY17mRXBzln+CjI4Xv/iYwUp8RNporY3R0UO8dXrsueU4yTf2o9eNqshaiVQ1WanG6w4odg4GMAP3Aug+GvHENU1XTjF+NcJONuhC/GsEJVkGuGrA2jMcusWF1uvB+EPkHi2P28cex1/JpKqjo3M4pKr7Yt8lBaUBve9MhgAAAABJRU5ErkJggg==);
    background-size: 11px auto;
}

footer.global a.cta.instagram {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAAAAADh3zPnAAAA5ElEQVQYlYWRoW7DQBBEHzoUeMDEwF9QdiSSodGBEIMw/45lWTIxCzhu5TPMUhJyLCRfEemkKXBqtU6jDnzS7uzsAGByV/lDXR985XLDU801aVW6NgsNUgxD37Vt1w8hSgGgUSzXQcBFNWBicj9gNbqPFA2F5gVk+30GkyZmFZQaAbLwkB4hK8eKUSVePWAvup9Od112QC9PrQ4ImizYswLQqeaoFvJ02wHsbimHVscFew2L7yD/P35d8m15tmCn1fL3gZ/2eeAmDsCochseYFaxfRXgUjQvjzVlVPO+hnel/VXxF8HPsbzWjaJUAAAAAElFTkSuQmCC);
    background-size: 11px auto;
}

footer.global a.title {
    background-size: 173px auto;
    background-image: url("../resources/images/ORCH_Institute_White.png");
    display: block;
    overflow: hidden;
    text-indent: -50em;
    width: 173px;
    height: 150px;
    margin: auto;
}

footer.global ul.nav {
    text-transform: lowercase;
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
}

footer.global ul.nav li {
    display: inline-block;
    margin: 5px 7px;
}

footer.global ul.nav a {
    font-size: .7em;
}

footer.global > p {
    display: block;
    clear: both;
    margin: 20px 0;
    text-transform: uppercase;
    font-size: .7em;
    letter-spacing: .1em;
    line-height: 1.3;
}

@media (min-width: 49em) {
    footer.global > p {
        margin: 45px 0 0;
    }
}

footer.global > p + p {
    margin-top: 10px;
}

footer.global > p a {
    text-decoration: underline;
}
